<template>
  <div class="container-page">
    <div id="background"></div>
    <div class="full-page">
      <div class="main-container">
        <div class="container-top">
          <div class="text-content">
            <div class="main-title">Hi, I am Buse</div>
            <div class="main-subtitle">I am a UX/UI designer</div>
            <div class="main-subtitle">
              Problem solving and playful teammates are my therapy.
            </div>
            <div class="main-subtitle">Let’s design through life!</div>
          </div>
        </div>
        <div class="container-bottom">
          <img id="hand" />
        </div>
      </div>
    </div>
    <div id="projects" class="projects">
      <div class="list-grid list-2">
        <Project
          v-for="project of projects"
          :key="project.name"
          :name="project.name"
          :title="project.title"
          :subtitle="project.subtitle"
          :presentation="project.presentation"
          :descriptions="project.descriptions"
          :is-mobile="isMobile"
          @click="goTo(project.name)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Project from "@/components/Project.vue";
import variables from "@/assets/scss/variables.scss";
import {
  projectHealthie,
  projectTaxyMatch,
  // projectNotion,
  // projectMeandre,
} from "@/helpers/content.js";
export default {
  name: "Home",
  components: {
    Project,
  },
  data() {
    return {
      isMobile:
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
      projects: [
        { ...projectHealthie },
        { ...projectTaxyMatch },
        // { ...projectMeandre },
        // { ...projectNotion },
      ],
      variables,
    };
  },
  created() {
    if (!this.isMobile) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    console.log("ROUTE PARAMS", this.$route.params);
    if (this.$route.params.projects) {
      const projects = document.getElementById("projects");
      projects.scrollIntoView({ behavior: "smooth" });
    }
  },
  beforeUnmount() {
    if (!this.isMobile) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    handleScroll() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // true for mobile device
        console.log("mobile device");
      } else {
        // false for not mobile device
        console.log("not mobile device");
      }
      let documentHeight = document.body.scrollHeight;
      let currentScroll = window.scrollY + window.innerHeight;
      let modifier = 1;
      if (currentScroll + modifier >= documentHeight) {
        window.scrollTo(0, window.scrollY - 1);
      }
    },
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
#background {
  background-color: $home-main-bg;
}
.full-page {
  background-color: $home-main-bg;
  height: calc(100vh - 3rem);
}

.main-container,
.full-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-container {
  flex-grow: 1;
}

.container-top {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .text-content {
    text-align: center;
  }
}

.container-bottom {
  display: flex;
  align-items: baseline;
}

.projects {
  margin-top: 5rem;
}

#hand {
  width: 100%;
  content: url("../assets/images/home/hand-mobile.svg");
}

@media screen and (min-width: $screen-medium) {
  #hand {
    content: url("../assets/images/home/hand-2.svg");
  }
}
</style>
