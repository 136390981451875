<template>
  <div
    v-if="showFooter"
    class="container-main"
    id="footer"
    :style="setBackground"
  >
    <div class="container-logo" @click="goTo('home')">
      <img src="../assets/images/logo.svg" alt="" />
    </div>
    <div class="container-pages">
      <img
        @click="nav('https://linkedin.com/in/busekilinc')"
        src="../assets/images/footer-linkedin.svg"
        alt=""
      />

      <img
        @click="nav('https://medium.com/@busekilinc')"
        src="../assets/images/footer-medium.svg"
        alt=""
      />

      <img
        @click="nav('mailto:k.busedilara@gmail.com')"
        src="../assets/images/footer-mail.svg"
        alt=""
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";

#footer {
  // margin-top: 5rem;
}

img {
  height: 2rem;
  cursor: pointer;
}
.container-main {
  color: $text-grey;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container-logo {
  font-family: "LimeLight", sans-serif;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  cursor: pointer;
  img {
    filter: invert(100%) sepia(0%) saturate(6330%) hue-rotate(358deg)
      brightness(132%) contrast(74%);
  }
}
.container-pages {
  display: flex;
  align-items: center;
  grid-gap: 1rem;

  div {
    cursor: pointer;
    font-weight: 400;
  }
}

@media screen and (max-width: $screen-mobile) {
  .container-pages div {
    font-weight: 600;
  }
}
</style>

<script>
export default {
  name: "Footer",
  props: {
    background: {
      type: String,
      default: "",
    },
  },
  computed: {
    showFooter() {
      // return this.$route.name !== "about";
      return true;
    },
    setBackground() {
      return `background-color: ${this.background || "rgba(0, 0, 0, 0.3)"}`;
    },
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
    nav(url) {
      window.open(url, "_blank");
    },
    goToProjects() {
      if (this.$route.name === "home") {
        const projects = document.getElementById("projects");
        projects.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$router.push({ name: "home", params: { projects: true } });
      }
    },
  },
};
</script>
