<template>
  <Header />
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <Footer />
</template>

//
<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
export default {
  components: {
    Footer,
    Header,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;300;400;600;700;900&family=IBM+Plex+Serif:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Limelight&display=swap");
@import "./assets/scss/variables.scss";
html {
  overflow: scroll;
  overflow-x: hidden;
  font-size: 16px;
}
// ::-webkit-scrollbar {
//   width: 0; /* Remove scrollbar space */
//   background: transparent; /* Optional: just make scrollbar invisible */
// }

#app {
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  // background-color: #191b1e;
  background-color: #262626;
  color: $text-grey;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // border-radius: 10px;
    background-color: #262626;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $text-grey;
  }
}

#background {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9;

  #curve {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.container-page {
  margin-bottom: 5rem;
}

.project-container {
  padding: 0 1rem 1rem 1rem;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s ease-in-out;
}

.page-container-old {
  width: 100vw;
  height: calc(100vh - 50px - $base-spacing * 4);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top-container {
    height: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4vh 0;
    width: 100%;
    position: relative;
    color: $text-grey;
  }

  .title-container {
    margin: 0 auto;
  }

  .content-block {
    display: grid;
    width: 100vw;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .highlights-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    .highlight-item {
      padding: 0 16px;
      width: fit-content;
    }
  }

  .title {
    font-size: $base-font-size * 6;
  }
  .subtitle {
    font-size: $base-font-size * 2;
    line-height: $base-spacing * 5;
  }

  .about-title {
    font-size: $base-font-size * 2;
  }

  .description-container,
  .highlights-container,
  .pills-container {
    font-size: $base-font-size;
    line-height: $base-spacing * 3;
  }

  .description-item,
  .highlight-item {
    font-size: $base-font-size * 1.5;
  }
}

// * NEW CSS START

.full-page {
  height: 100vh;
}

.nav {
  position: absolute;
  top: 0;
  left: 0;
}

.container-top {
  padding: 0 2rem;
}

.main-title {
  font-family: "LimeLight", sans-serif;
  font-size: 2rem;
  color: $text-black;
  margin-bottom: 0.5rem;
  font-weight: 300;
}

.main-subtitle {
  font-size: 1.5rem;
  color: $text-black;
  font-weight: 400;
}

.main-descriptions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.3rem;
  color: $text-black;
  font-weight: 300;
}

.image-container {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.list-flex {
  display: flex;
}

.list-grid {
  display: grid;

  &.list-2 {
    grid-template-columns: 1fr 1fr;
  }
  &.list-2-3 {
    grid-template-columns: 2fr 1fr;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
}

.impact {
  font-size: 4rem;
  font-weight: 900;
}

.full {
  width: 100%;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
.top-1 {
  margin-top: 1rem;
}
.top-2 {
  margin-top: 2rem;
}
.top-3 {
  margin-top: 3rem;
}
.top-4 {
  margin-top: 4rem;
}
.top-5 {
  margin-top: 5rem;
}
.bold {
  font-weight: bold;
}

.pull-up-1 {
  position: relative;
  top: -1rem;
}

a {
  color: inherit;
}

// * NEW CSS END

// * DESKTOP SIZE
@media screen and (min-width: $screen-desktop) {
  .page-container-old {
    .title {
      font-size: $base-font-size * 6;
    }
    .subtitle {
      font-size: $base-font-size * 2;
      line-height: $base-spacing * 5;
    }

    .about-title {
      font-size: $base-font-size * 2;
    }

    .description-container,
    .highlights-container,
    .pills-container {
      font-size: $base-font-size;
      line-height: $base-spacing * 3;
    }

    .description-item,
    .highlight-item {
      font-size: $base-font-size * 1.5;
    }
  }
}

@media screen and (min-width: $screen-desktop-large) {
  .page-container-old {
    .title {
      font-size: $base-font-size * 8;
    }
    .subtitle {
      font-size: $base-font-size * 3;
      line-height: $base-spacing * 8;
    }

    .about-title {
      font-size: $base-font-size * 2.5;
    }

    .description-container,
    .highlights-container,
    .pills-container {
      font-size: $base-font-size * 2;
      line-height: $base-spacing * 5;
    }

    .description-item,
    .highlight-item {
      font-size: $base-font-size * 2.5;
    }
  }
}

// ! NEW MEDIA QUERIES
@media screen and (min-width: $screen-desktop) {
  html {
    font-size: 20px;
  }
}

@media screen and (min-width: $screen-desktop-large) {
  html {
    font-size: 28px;
  }
}

@media screen and (min-width: 768px) {
  .only-mobile {
    display: none !important;
  }

  .only-desktop {
    display: block;
  }

  .only-not-mobile {
    display: block;
  }
}

@media screen and (max-width: $screen-mobile) {
  .hide-mobile {
    display: none;
  }

  .only-mobile {
    display: block;
  }

  .only-desktop {
    display: none !important;
  }
  .only-not-mobile {
    display: none;
  }
  .container-top {
    padding: 0 1rem;
  }
  .main-title {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .main-subtitle {
    font-size: 1.2rem;
  }

  .list-grid {
    &.list-2,
    &.list-2-3 {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (min-width: $screen-mobile) and (max-width: $screen-desktop) {
  .main-title {
    font-size: 3rem;
  }

  .main-subtitle {
    font-size: 1.5rem;
  }
}

@media screen and (min-height: $screen-tall) {
  .main-title {
    margin: 2rem 0;
  }
}

@media screen and (min-width: $screen-desktop-large) and (max-height: 900px) {
  .main-title {
    font-size: 4rem;
  }

  .main-subtitle {
    font-size: 1.5rem;
  }
}
</style>
