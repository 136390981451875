<template>
  <div class="project" :class="setDesktop">
    <img
      :src="require('../assets/images/projects/' + name + '/thumbnail.png')"
      :alt="title"
    />
    <div class="titles">
      <div class="title">
        {{ title }}
      </div>
      <div class="subtitle">
        {{ subtitle }}
      </div>
    </div>

    <div v-if="!isMobile" class="presentation">
      <!-- <div v-for="desc of descriptions" :key="desc">
        {{ desc }}
      </div> -->
      {{ presentation }}
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.project {
  text-align: center;
  padding: 2rem;
  max-width: 400px;
  margin: 0 auto;
}

.desktop {
  &:hover {
    cursor: url("../assets/images/cursors/arrow-forward.svg"), auto;

    .titles {
      transform: scale(0.7) translateY(-50px);
    }

    .presentation {
      opacity: 1;
      transform: translateY(-50px);

      div {
        padding: 0 0.5rem;
      }
    }
  }
  padding: 0;
}

img {
  max-height: 200px;
}

.titles {
  transition: all 0.2s ease-out;
  margin-top: 2rem;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  transition: all 0.2s ease-out;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 200;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease-out;
}

.presentation {
  text-align: center;
  opacity: 0;
  font-size: 0.8rem;
  font-weight: 300;
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transform: translateY(50px);
  transition: all 0.2s ease-out;

  div {
    border-right: 1px solid #ccc;
  }
}

@media screen and (min-width: $screen-desktop-large) {
  .project {
    max-width: 800px;
  }
}
</style>

<script>
export default {
  name: "Project",
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    presentation: {
      type: String,
      required: true,
    },
    descriptions: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    setDesktop() {
      return this.isMobile ? "" : "desktop";
    },
  },
};
</script>
