<template>
  <div v-if="showHeader" class="container-nav">
    <div class="container-logo" @click="goTo('home')">
      <img src="../assets/images/logo.svg" alt="" />
      <div>Buse Kilinc</div>
    </div>
    <div class="container-pages">
      <div @click="goToProjects">My projects</div>
      <div @click="goTo('about')">About me</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.container-nav {
  color: $text-black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.container-logo {
  font-family: "LimeLight", sans-serif;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  cursor: pointer;
}
.container-pages {
  display: flex;
  align-items: center;
  grid-gap: 1rem;

  div {
    cursor: pointer;
    font-weight: 400;
  }
}

@media screen and (max-width: $screen-mobile) {
  .container-pages div {
    font-weight: 600;
  }
}
</style>

<script>
export default {
  name: "Header",
  computed: {
    showHeader() {
      // return this.$route.name !== "about";
      return true;
    },
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
    goToProjects() {
      if (this.$route.name === "home") {
        const projects = document.getElementById("projects");
        projects.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$router.push({ name: "home", params: { projects: true } });
      }
    },
  },
};
</script>
