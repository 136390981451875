/* eslint-disable prettier/prettier */
import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/projects",
    name: "projects",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Projects.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutConstruction.vue"),
  // },
  {
    path: "/projects/healthie",
    name: "healthie",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/projects/Healthie.vue"),
  },
  {
    path: "/projects/taxymatch",
    name: "taxymatch",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/projects/Taxymatch.vue"),
  },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
});

export default router;
