import variables from "../assets/scss/variables.scss";

export const aboutUxText =
  "My curiosity for HTML & CSS made me decide to change my career. I discovered my passion for UX/UI design while I was learning front-end development. As much as I enjoyed coding, I found myself more excited about defining problems and finding user-centered solutions through digital products by designing user flows and interfaces. This realization made me decide to take a real step towards UX/UI. I took Figma and UI courses and finally by joining Ironhack France, I aim to learn and practice design thinking process and best practices.";

export const aboutSkillsArr = [
  {
    text: "Figma",
    device: null,
  },
  {
    text: "Prototyping",
    device: null,
  },
  {
    text: "Design thinking",
    device: null,
  },
  {
    text: "User interface design",
    device: null,
  },
  {
    text: "User research",
    device: null,
  },
  {
    text: "User journey",
    device: "desktop",
  },
  {
    text: "User stories and journeys",
    device: "mobile",
  },
  {
    text: "Design principles",
    device: null,
  },
  {
    text: "Atomic design",
    device: null,
  },
  {
    text: "Design system",
    device: "desktop",
  },
  {
    text: "Responsive design",
    device: null,
  },
  {
    text: "Site maps & Flow charts",
    device: null,
  },
  {
    text: "Micro interactions",
    device: null,
  },
  {
    text: "HTML & CSS",
    device: null,
  },
  {
    text: "Sketching",
    device: "desktop",
  },
  {
    text: "English",
    device: "desktop",
  },
  {
    text: "French",
    device: "desktop",
  },
  {
    text: "English & French",
    device: "mobile",
  },
];

export const aboutBeforeText =
  "I studied Product design at university and graphic design at high school. Right after graduation, I started working as product / interior designer. Moving to France, I worked on my 3D modeling skills and worked for a little while as a freelancer. Due to my interest for programming, I took a career break to learn coding. I followed several online courses while practicing at the same time. I used my developer friends’ help and improved myself quite well. Big realization that I was always more interested in designing the pages than coding them led me to take real steps towards UX/UI. Now I am using the knowledge I gained for coding on my design and collobration with devs.";

export const projectHealthie = {
  name: "healthie",
  title: "Healthie",
  subtitle: "mHealth application design",
  subtitle1: "Wellness app to create healthy eating habits",
  presentation: "10 days  |  Team-work  |  MVP",
  descriptions: ["Design thinking process", "10 days", "Team-work", "MVP"],
  color: variables.projectsHealthieColor,
};

export const projectMeandre = {
  name: "meandre",
  title: "Le Méandre",
  subtitle: "Digitalising a local bookstore",
  subtitle1: "Digitalising a local bookstore",
  presentation:
    "This is the presentation of the project that we have on the homepage. Hello blablableablablablablabla",
  descriptions: [
    "2 weeks",
    "group of 3 UX/UI designers",
    "A real bookstore in Paris",
  ],
  color: variables.projectsMeandreColor,
};

export const projectTaxyMatch = {
  name: "taxymatch",
  title: "TaxyMatch",
  subtitle: "Re-design of the booking flow of a shared taxi ride",
  subtitle1: "PLACEHOLDER",
  presentation: "14 days  |  Individual work  |  Startup company",
  descriptions: ["14 days", "Individual work", "Startup company"],
  color: variables.projectsMeandreColor,
};

export const projectNotion = {
  name: "notion",
  title: "Notion app",
  subtitle: "Re-design project",
  subtitle1: "Re-design project",
  presentation:
    "This is the presentation of the project that we have on the homepage. Hello blablable",
  descriptions: [
    "3 days",
    "Heuristic evaluation on existing app",
    "Deliver 3-7 pages",
  ],
  color: variables.projectsNotionColor,
};
